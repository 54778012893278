<script>
import API from "@/plugins/axios-method";
import DataLoader from "@/components/data-loader.vue";
import FundingProgramFormModal from "@/components/dashboard/products/funding-program/funding-program-form-modal.vue";
import FundingProgramTable from "@/components/dashboard/products/funding-program/funding-program-table.vue";

export default {
  name: "product-show-funding-program",
  components: {
    FundingProgramTable,
    FundingProgramFormModal,
    DataLoader
  },
  props: {

  },
  data(){
    return {
      fundingPrograms: [],
      contributionPrograms: [],
      showDataLoader: true,
      activeFundingProgram: null,
      formModal: false
    }
  },
  mounted() {
    this.getContributionPrograms()
    this.getFundingPrograms()
  },
  methods: {
    getContributionPrograms() {
      API.get('/contribution_programs')
          .then(response => {
            response.data['hydra:member'].map(c => {
              this.contributionPrograms.push(c)
            })
          })
          .catch()
    },
    getFundingPrograms() {
      API.get('/funding_programs')
          .then(response => {
            response.data['hydra:member'].map(f => {
              this.fundingPrograms.push(f)
            })
          })
          .catch()
          .finally(() => {
            this.showDataLoader = false
          })
    },
    closeFormModal(){
      this.activeFundingProgram = null
      this.formModal = false
    },
    openFormModal() {
      this.formModal = true
    },
    openUpdateFundingProgramModal(f) {
      this.activeFundingProgram = f
      this.formModal = true
    },
    updateFundingTable(funding){
      this.fundingPrograms = this.fundingPrograms.filter(f => f.id !== funding.id)
      this.fundingPrograms.unshift(funding)
    }
  }
}
</script>

<template>
  <div class="col">
    <FundingProgramFormModal
        v-if="contributionPrograms.length > 0"
        @onModalClose="closeFormModal"
        @fundingProgramCreated="updateFundingTable"
        :active-funding-program="activeFundingProgram"
        :contribution-programs="contributionPrograms"
        v-model="formModal"
    />
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <h4>Programme de financement</h4>
          <b-button @click="openFormModal" variant="primary" class="btn btn-primary waves-effect waves-light">
            + ajouter
          </b-button>
        </div>
      </div>
      <DataLoader v-if="showDataLoader"/>
      <div class="card-body" v-if="!showDataLoader">
        <FundingProgramTable
            :funding-programs="fundingPrograms"
            @openUpdateFundingProgramModal="openUpdateFundingProgramModal"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>